/* TimePicker */
@import '~rc-time-picker/assets/index.css';
/* DatePicker */
@import '~react-dates/lib/css/_datepicker.css';
/* FileInput */
@import '~react-dropzone-component/styles/filepicker.css';
@import '~dropzone/dist/min/dropzone.min.css';

/* Font Awesome */
@import '~font-awesome/scss/font-awesome.scss';

/* Bootstrap */
@import '~bootstrap/dist/css/bootstrap.min.css';
